import Vue from 'vue'
import Vuex, {ActionContext} from 'vuex'
export interface AppState{
    appStatus:AppStatus;
}
export enum AppStatus{
    loading,
    ready,
}

const localBasePath = '~/'
Vue.use(Vuex);

export default new Vuex.Store<AppState>({
    state: {
        appStatus: AppStatus.loading,
    },
    getters: {
        appStatus: (state, getters, rootState, rootGetters) => state.appStatus,
        isLoading: (state, getters, rootState, rootGetters) => getters.appStatus === AppStatus.loading,
        isReady: (state, getters, rootState, rootGetters) => getters.appStatus === AppStatus.ready
    },
    mutations: {
        setAppState(state: AppState, value: AppStatus): void {
            state.appStatus = value;
        },
    },
    actions: {
        async load(ctx: ActionContext<AppState, AppState>): Promise<void> {
            ctx.commit("setAppState", AppStatus.ready);
        },
    },
    modules: {}
})
