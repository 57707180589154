import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import UdfContent from "./components/UdfPreview/UdfContent.vue";
import UdfDocument from "./components/UdfPreview/UdfDocument.vue";
import UdfElement from "./components/UdfPreview/UdfElement.vue";
import UdfElementCollection from "./components/UdfPreview/UdfElementCollection.vue";
import UdfParagraph from "./components/UdfPreview/UdfParagraph.vue";
import UdfTable from "./components/UdfPreview/UdfTable.vue";
import UdfRow from "./components/UdfPreview/UdfRow.vue";
import UdfCell from "./components/UdfPreview/UdfCell.vue";
import UdfTag from "./components/UdfPreview/UdfTag.vue";
import UdfTab from "./components/UdfPreview/UdfTab.vue";
import UdfField from "./components/UdfPreview/UdfField.vue";
import UdfSpace from "./components/UdfPreview/UdfSpace.vue";
import UdfPreview from "./components/UdfPreview/UdfPreview.vue";
Vue.component('udf-content',UdfContent);
Vue.component('udf-document',UdfDocument);
Vue.component('udf-element',UdfElement);
Vue.component('udf-element-collection',UdfElementCollection);
Vue.component('udf-paragraph',UdfParagraph);
Vue.component('udf-table',UdfTable);
Vue.component('udf-row',UdfRow);
Vue.component('udf-cell',UdfCell);
Vue.component('udf-tag',UdfTag);
Vue.component('udf-tab',UdfTab);
Vue.component('udf-field',UdfField);
Vue.component('udf-space',UdfSpace);
Vue.component('udf-preview',UdfPreview);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
