var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.elem.tagName == 'space')?_c('udf-space',{attrs:{"elem":_vm.elem,"data":_vm.data,"styles":_vm.styles},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var options = ref.options;
var elem = ref.elem;
var data = ref.data;
return _vm._l((elem.children),function(item,index){return _c('udf-element',{key:index,attrs:{"index":index,"parentOptions":options,"elem":item,"data":data,"styles":_vm.styles}})})}}],null,false,242703130)}):(_vm.elem.tagName == 'content')?_c('udf-content',{style:(_vm.inlineCss),attrs:{"tab-set":_vm.tabSet,"elem":_vm.elem,"data":_vm.data,"styles":_vm.styles},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var options = ref.options;
var elem = ref.elem;
var data = ref.data;
return _vm._l((elem.children),function(item,index){return _c('udf-element',{key:index,attrs:{"index":index,"parentOptions":options,"elem":item,"data":data,"styles":_vm.styles}})})}}])},[_c('code',[_vm._v(_vm._s(_vm.cssRules))])]):(_vm.elem.tagName == 'paragraph')?_c('udf-paragraph',{style:(_vm.inlineCss),attrs:{"elem":_vm.elem,"data":_vm.data,"styles":_vm.styles},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var options = ref.options;
var elem = ref.elem;
var data = ref.data;
return _vm._l((elem.children),function(item,index){return _c('udf-element',{key:index,attrs:{"index":index,"parentOptions":options,"elem":item,"data":data,"styles":_vm.styles}})})}}])}):(_vm.elem.tagName == 'tab')?_c('udf-tab',{attrs:{"parentOptions":_vm.parentOptions,"elem":_vm.elem,"data":_vm.data,"styles":_vm.styles},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var options = ref.options;
var elem = ref.elem;
var data = ref.data;
return _vm._l((elem.children),function(item,index){return _c('udf-element',{key:index,attrs:{"index":index,"parentOptions":options,"elem":item,"data":data,"styles":_vm.styles}})})}}])}):(_vm.elem.tagName == 'tag')?_c('udf-tag',{attrs:{"parentOptions":_vm.parentOptions,"elem":_vm.elem,"data":_vm.data,"styles":_vm.styles},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var options = ref.options;
var elem = ref.elem;
var data = ref.data;
return _vm._l((elem.children),function(item,index){return _c('udf-element',{key:index,attrs:{"index":index,"parentOptions":options,"elem":item,"data":data,"styles":_vm.styles}})})}}])}):(_vm.elem.tagName == 'field')?_c('udf-field',{style:(_vm.inlineCss),attrs:{"parentOptions":_vm.parentOptions,"elem":_vm.elem,"data":_vm.data,"styles":_vm.styles},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var options = ref.options;
var elem = ref.elem;
var data = ref.data;
return _vm._l((elem.children),function(item,index){return _c('udf-element',{key:index,attrs:{"index":index,"parentOptions":options,"elem":item,"data":data,"styles":_vm.styles}})})}}])}):(_vm.elem.tagName == 'table')?_c('udf-table',{attrs:{"parentOptions":_vm.parentOptions,"elem":_vm.elem,"data":_vm.data,"styles":_vm.styles},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var options = ref.options;
var elem = ref.elem;
var data = ref.data;
return _vm._l((elem.children),function(item,index){return _c('udf-element',{key:index,attrs:{"index":index,"parentOptions":options,"elem":item,"data":data,"styles":_vm.styles}})})}}])}):(_vm.elem.tagName == 'row')?_c('udf-row',{attrs:{"parentOptions":_vm.parentOptions,"elem":_vm.elem,"data":_vm.data,"styles":_vm.styles},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var options = ref.options;
var elem = ref.elem;
var data = ref.data;
return _vm._l((elem.children),function(item,index){return _c('udf-element',{key:index,attrs:{"index":index,"parentOptions":options,"elem":item,"data":data,"styles":_vm.styles}})})}}])}):(_vm.elem.tagName == 'cell')?_c('udf-cell',{attrs:{"parentOptions":_vm.parentOptions,"index":_vm.index,"elem":_vm.elem,"data":_vm.data,"styles":_vm.styles},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var options = ref.options;
var elem = ref.elem;
var data = ref.data;
return _vm._l((elem.children),function(item,index){return _c('udf-element',{key:index,attrs:{"index":index,"parentOptions":options,"elem":item,"data":data,"styles":_vm.styles}})})}}])}):(_vm.elem.tagName == undefined)?_c('span'):_c('span',[_c('v-card',{attrs:{"outlined":"","dark":""}},[_vm._v(" unknown tag : "+_vm._s(_vm.elem)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }